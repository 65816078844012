<mat-drawer-container autosize class="layout background-tools responsive">

  <!-- Code to open drawer: <mat-icon aria-label="Sidebar" (click)="drawer.toggle()">menu</mat-icon> -->
  <mat-drawer #drawer mode="over" class="layout-drawer">
  </mat-drawer>

  <mat-drawer-content class="layout-content">
    <div class="layout-content">

      <header class="layout-content__header" *ngIf="!hideHeader">
        <of-header></of-header>
      </header>

      <main class="layout-content__main">
        <router-outlet></router-outlet>
      </main>

      <footer class="layout-content__footer">
        <of-footer></of-footer>
      </footer>

    </div>
  </mat-drawer-content>
</mat-drawer-container>
