import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

/**
 * The master layout component which is used within the app component to wrap the header, footer, and main sections for the entire app.
 */
@Component({
  selector: 'of-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  hideHeader = false;
  constructor(
    router: Router,
    route: ActivatedRoute) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const routeData = this.getRouteData(route.snapshot);
      this.hideHeader = routeData.data?.hideHeader || false;
    });
  }

  private getRouteData(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.firstChild) {
      return this.getRouteData(route.firstChild);
    }

    return route;
  }
}
