import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomError } from '@orthofi/ngx-error-handler';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICredentials } from 'src/app/shared/interfaces/ICredentials';
import { IMfaCodeModel } from 'src/app/shared/interfaces/IMfaCodeModel';
import { IEnvironmentConfig } from 'src/webpack/extra-webpack-types';

/** Credentials to be used when interacting with the OrthoFi Keymaster API */
const httpOptions = { withCredentials: true };

// TODO: Move these interfaces to their own files.
/** The generic response Keymaster will return. */
export interface IKeymasterResponse {
  /** Text information about the status of the result. */
  Message: string;
}
/** The response returned by Keymaster when a login attempt is made. */
export interface ILoginResponse {
  /** A url Keymaster recommends the user should be sent to after login. */
  redirectUrl: string;
  isMfaRequired: boolean;
  isMfaConfigurationRequired: boolean;
  mfaDisplayName: string;
  mfaPhoneNumberDisplay: string;
  isLegacyPasswordFlow: boolean;
}
/** The post information required by Keymaster for password resets. */
export interface IPasswordResetPayload {
  /** The single use token that was generated and sent to the user. */
  resetToken: string;
  /** The desired new password. */
  newPassword: string;
}
/** The post information required by Keymaster for identifying a user. */
export interface IIdentifyPayload {
  username: string;
}
/** the response returned by Keymaster when identifying a user. */
export interface IIdentifyResponse {
  isInternalUser: boolean;
}

export interface IExchangeOpenIdTokenPayload {
  accessToken: string;
  idToken: string;
}

/** An API based data service for interacting with the Keymaster API, including authenticating a user's credentials and reset them. */
@Injectable()
export class AuthenticationService {
  /** @ignore */
  apiRoot = 'https://keymaster.orthofi.com';

  /** @ignore */
  constructor(
    @Inject('ENV') private _env: IEnvironmentConfig,
    private _http: HttpClient
  ) { this.apiRoot = `${_env.authenticationApi}/api/account`; }

  /**
   * Log in to the system and have the session cookie attached.
   * @param login An object containing credentials.
   */
  authenticate(login: ICredentials): Observable<ILoginResponse> {
    return this._http
      .post<any>(`${this.apiRoot}/login`, login, httpOptions)
      .pipe(
        catchError( ( loginError: any ) => {
          throw loginError;
        })
      );
  }

  identify(identification: IIdentifyPayload): Observable<IIdentifyResponse> {
    return this._http
      .post<any>(`${this.apiRoot}/identify`, identification, httpOptions)
      .pipe(
        catchError( ( identifyError: any ) => {
          throw identifyError;
        })
      );
  }

  exchangeOpenIdToken(payload: IExchangeOpenIdTokenPayload): Observable<ILoginResponse> {
    return this._http
      .post<any>(`${this.apiRoot}/exchange-openid-token`, payload, httpOptions)
      .pipe(
        catchError( ( exchangeError: any ) => {
          throw exchangeError;
        })
      );
  }

  verifyCode(model: IMfaCodeModel): Observable<ILoginResponse> {
    return this._http
      .post<any>(`${this.apiRoot}/verify-mfa`, model, httpOptions)
      .pipe(
        catchError( ( loginError: any ) => {
          throw loginError;
        })
      );
  }

  /**
   * Log out and have the session cookie deleted. Will not redirect.
   */
  logout(): Observable<ILoginResponse> {
    return this._http
      .get<any>(`${this.apiRoot}/logout?should_redirect=false`, httpOptions)
      .pipe(
        catchError( ( loginError: any ) => {
          return throwError(new CustomError (
            `Sorry, we couldn't clear session data. Please try again.`,
            { level: 'info', notificationPriority: 'minor', showSupportId: false, errorObject: loginError }
          ));
        })
      );
  }

  /**
   * Using a previously generated `resetToken`, change the associated account's password to a `newPassword`.
   * @param reset An object containing the `resetToken` and `newPassword`.
   */
  resetPassword(reset: IPasswordResetPayload): Observable<IKeymasterResponse> {
    return this._http
      .post<any>(`${this.apiRoot}/resetpassword`, reset, httpOptions)
      .pipe(
        catchError( ( resetError: any ) => {
          //return throwError(new CustomError(
          //  `Sorry, we couldn't reset the password at this time. Please try again.`,
          //  { showSupportId: true, errorObject: resetError }
          //));
          throw resetError;
        } )
      );
  }

  /**
   * Make a request to check if the username exists and have an email containing the reset token sent.
   * @param username The username of the account to check for and contact.
   */
  sendForgotPasswordEmail(username: string): Observable<IKeymasterResponse> {
    return this._http
      .post<any>(`${this.apiRoot}/resetpasswordemail`, { username }, httpOptions)
      .pipe(
        catchError((sendEmailError: any) => {
          const noUser = (
            sendEmailError.error &&
            sendEmailError.error.error_message &&
            (sendEmailError.error.error_message.indexOf('No user found') < 1)
          );

          // TODO: Should this message reveal that the email address isn't registered?
          return throwError(new CustomError(
            // eslint-disable-next-line max-len
            `Sorry, we either encountered an error when attempting to send the email or an account with that username doesn't exist. If an email doesn't arrive, please refresh this page and try again or contact your practice for assistance.`,
            { showSupportId: !noUser, level: noUser ? 'info' : 'error', errorObject: sendEmailError }
          ));
        })
      );
  }
}
