import { IErrorHandlerOptions } from '@orthofi/ngx-error-handler';

/**
 * This is the configuration automatically injected in to our application via Webpack. It's technically
 * available everywhere as a global, but we want to be using the provided environment in most cases
 * anyway. So, we'll require that we manually import if from this module instead of letting it
 * automatically get imported via a type declaration file.
 */
declare let APP_CONFIG: IEnvironmentConfig;

export default APP_CONFIG;

/** This interface is for outlining all of the potential configuration points in the app's environment files. */
export interface IEnvironmentConfig {
  /** Should Angular enableProdMode be called. */
  production: boolean;
  /** The root URL for the Keymaster API, including port if applicable and protocol. No trailing slash. */
  authenticationApi: string;
  /** The root URL for the Gatekeeper API, including port if applicable and protocol. No trailing slash. */
  gatekeeperUrl: string;
  /** The root URL for the Legacy application, including port if applicable and protocol. */
  portalUrl: string;
  /** A NGX-Error-Handler configuration object. */
  errorHandlerOptions: IErrorHandlerOptions;
  /** A Sentry configuration object. */
  errorLoggerOptions: any;
  /** Okta issuer */
  oktaDomain?: string;
  /** Okta client ID */
  oktaClientId?: string;
}
