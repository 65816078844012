import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IEnvironmentConfig } from 'src/webpack/extra-webpack-types';

// /** Credentials to be used when interacting with the OrthoFi Keymaster API */
// const httpOptions = { withCredentials: true };

export interface ISignInConfigResponse {
  isIdentifierFirstEnabled: boolean;
  isSsoLinkEnabled: boolean;
}

@Injectable()
export class ConfigService {
  /** @ignore */
  apiRoot = 'https://keymaster.orthofi.com';

  /** @ignore */
  constructor(
    @Inject('ENV') private _env: IEnvironmentConfig,
    private _http: HttpClient
  ) { this.apiRoot = `${_env.authenticationApi}/api`; }

  getConfig(): Observable<ISignInConfigResponse> {
    return this._http
      .get<any>(`${this.apiRoot}/config/sign-in`)
      .pipe(
        catchError(() => of({ isIdentifierFirstEnabled: false }))
      );
  }
}
